import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wascas from './pages/Wascas';
import AddWasca from './pages/AddWasca';
import ShowPost from './pages/ShowPost';
import Login from './pages/Login';
import Signup from './pages/Signup';
import PrivateRoute from './components/PrivateRoute'
import AuthorizedRoute from './components/AuthorizedRoute'
import EditWasca from './pages/EditWasca';
import Home from './pages/Home';
import Bookmark from './pages/Bookmark';
import Profile from './pages/Profile';
import Forgot from './pages/Forgot';
import ResetPassword from './pages/ResetPassword';
import ResetFail from './pages/ResetFail';
import Success from './pages/Success';
import AdminRoute from './components/AdminRoute';
import NotFoundPage from './pages/NotFoundPage';


function App() {
  return (
    <>
    
      <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/wascas' element={<Wascas />} />
          <Route path='/forgot' element={<Forgot />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route path='/forgot-password/success' element={<Success />} />
          <Route path='/reset-password/failed' element={<ResetFail />} />
          <Route path='/wascas/wasca/:wascaId' element={<ShowPost />} />
     
        
          <Route path='/addwasca' element={<PrivateRoute />} >
            <Route path='' element={<AdminRoute />} >
              <Route path='' element={<AddWasca />} />
            </Route>
          </Route>
          <Route path='/edit-wasca/:wascaId' element={<PrivateRoute />} >
            <Route path='' element={<AdminRoute />} >
              <Route path='' element={<EditWasca />} />
            </Route>
          </Route>
          <Route path='/wascas/bookmarks' element={<PrivateRoute />} >
            <Route path='/wascas/bookmarks' element={<Bookmark />} />
          </Route>
          <Route path='/user/profile' element={<PrivateRoute />} >
            <Route path='/user/profile' element={<Profile />} />
          </Route>
        
          <Route path='/login' element={<AuthorizedRoute />} >
            <Route path='/login' element={<Login />} />
          </Route>
          <Route path='/signup' element={<AuthorizedRoute />} >
            <Route path='/signup' element={<Signup />} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
      </Routes>
  

    <ToastContainer />
    </>
  )
}

export default App;
