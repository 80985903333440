import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../assets/images/LOGO.png'
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

function ResetPassword() {

  const [formData, setFormData] = useState({
    password: '',
    confirm: ''
  });

  const params = useParams();

  const {password, confirm } = formData;
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onChange = (e) => {
    setFormData((prevState) => ({
        ...prevState,
        [e.target.id] : e.target.value
    }))
  }
  const onShowPassword = () => {
    if(showPassword === false) {
      setShowPassword(true)
    } else {
      setShowPassword(false)
    }
  }
  const onShowConfirm = () => {
    if(showConfirm === false) {
      setShowConfirm(true)
    } else {
      setShowConfirm(false)
    }
  }

  const onSubmit = async (e) => { 
    e.preventDefault()
    try {
      if (password === '') {
        toast.error('Please enter your password')
      } else if(confirm === '') {
        toast.error('Please enter your password')
      } else if(password !== confirm) {
        toast.error('Passwords need to match!')
      } else {
        setLoading(true)
        axios.post(`/users/reset/${params.token}`, formData)
        .then((response) => {
          if(!response.data.error) {
            setLoading(false)
            toast.success(response.data.message)
            navigate('/wascas')
          }
      }).catch((error) => {
          toast.error(error.response.data.error)
          setLoading(false)
          navigate('/reset-password/failed')
        })
      }
    } catch (error) {
      console.log(error)
    }
  
  }

  if(loading) {
    return <Spinner />
  }
  return (
    <div className='flex flex-col h-screen'>
      <div className="flex justify-center pb-16 h-1/6 items-center pt-12">
        <img src={Logo} alt="" className='w-2/3 md:w-1/3 lg:w-1/4 xl:w-1/5' />
      </div>
      
      <div className='flex flex-col items-center h-1/2 justify-center bg-white px-4'>
        <div className='flex flex-col items-center justify-center'>
        <p className='text-2xl mb-8 font-semibold'>Reset Your Password</p>
        <p className='mb-8'>Type in a new password that you'll use to log in.</p>
        <form onSubmit={onSubmit} className='flex flex-col w-full'>
        <div className='pb-4 flex flex-col text-sm'>
            <label htmlFor="password" className='font-semibold mb-2'>Password</label>
            <div className='flex items-center border-b-2 px-2 relative'>
                <FaLock className='absolute left-4' />
                <input 
                    className='text-sm border-0 pl-8 h-8 px-2 py-1 focus:outline-none appearance-none w-full rounded-full' 
                    type={showPassword === true ? "string" : "password"} 
                    name="password" id="password" value={password} onChange={onChange} placeholder='Password' />
                {showPassword === true ? 
                    <FaEyeSlash className='cursor-pointer absolute right-4' onClick={onShowPassword} /> :
                    <FaEye className='cursor-pointer absolute right-4' onClick={onShowPassword} />
                }
            </div>   
        </div>
        <div className='pb-4 flex flex-col text-sm'>
            <label htmlFor="password" className='font-semibold mb-2'>Confirm Password</label>
            <div className='flex items-center border-b-2 px-2 relative'>
                <FaLock className='absolute left-4' />
                <input 
                    className='text-sm border-0 pl-8 h-8 px-2 py-1 focus:outline-none appearance-none w-full rounded-full' 
                    type={showConfirm === true ? "string" : "password"}
                    name="confirm" id="confirm" value={confirm} onChange={onChange} placeholder='Password' />
                {showConfirm === true ? 
                    <FaEyeSlash className='cursor-pointer absolute right-4' onClick={onShowConfirm} /> :
                    <FaEye className='cursor-pointer absolute right-4' onClick={onShowConfirm} />
                }
            </div>  
        </div>
        <button type="submit" className='bg-gradient-to-r from-orange-500 via-slate-800 to-gray-950 hover:bg-gradient-to-l w-full text-white text-bold rounded-full py-3 mt-6'>Save</button>
        </form>
        </div>
      </div>
      
      <div class="flex justify-center items-end h-1/4">
        <p>&copy; Freelients 2023. All rights reserved</p>
      </div>
      
    </div>
  )
}

export default ResetPassword