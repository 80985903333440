import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import BookPostCard from '../components/BookPostCard.js'
import axios from 'axios'
import Spinner from '../components/Spinner'
import NotFound from '../components/NotFound.js'
import ServerError from '../components/ServerError.js';
import Login from './Login.js'


function Bookmark() {

  const [bookmarks, setBookmarks] = useState([])
  const [foundUser, setFoundUser] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [serverError, setServerError] = useState(false)


  useEffect(() => {
    const fetchUser = async () => {
      const response = await axios.get('/logged-in', { withCredentials: true });
      if (response.data.user) {
        setFoundUser(true)
        await axios.get('/bookmarks/fetchbookmarks')
        .then((response) => {
          setBookmarks(response.data)
        })
        .catch((error) => {
          setLoading(false)
          if(error.response.status === 404){
            setError(true)
          } else {
            setServerError(true)
          }
        })
        setLoading(false)
      } else {
        setFoundUser(false)
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  

  if(loading) {
    return <Spinner />
  }

  if(error) {
    return <NotFound />
  }
  if(serverError) {
    return <ServerError />
  }

  if(!foundUser) {
    return <Login />
  }

  return (
    <>
      {bookmarks.length > 0 ?
        <>
        <div className='text-center mt-8 mb-8 md:mt-16 md:mb-16'>
          <h1 className='text-3xl'>Here are your Saved Jobs!</h1>
        </div>
        
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-y-8 mx-2'>
            
              {bookmarks.map((book) => (
                <BookPostCard
                  key={book.wasca._id} 
                  wasca={book.wasca}
                  bookmark={book}
                  bookmarks={bookmarks}
                  setBookmarks={setBookmarks}
                />
              ))}
           
          </div>
          </> : <>
          <div className='h-screen flex flex-col justify-center items-center'>
              <h1 className='font-semibold'>Sorry, you have no saved leads yet!</h1>
              <Link 
                to="/wascas" 
                className='bg-gradient-to-r from-orange-500 via-slate-800 to-gray-950 hover:bg-gradient-to-l py-2 px-8 rounded-full text-white font-semi-bold mt-5'
              >Back to search</Link>
          </div>
          </>
        } 
        

        <Navbar />
    </>
  )
}

export default Bookmark
