import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Editor({ formData, setFormData, value }) {
  
  return (
    <div className=''>
      <ReactQuill 
        theme="snow" 
        required
        placeholder='Write something...'
        className='h-72 mb-12'
        onChange={(value) => {
          setFormData({ ...formData, content: value });
        }}
        value={value}
        
      />;
    </div>
  )
}

export default Editor