import React from 'react';
import Navbar from '../components/Navbar';

const NotFoundPage = () => {
  return (
  <>
    <Navbar />
    <div className='flex items-center flex-col justify-center h-screen'>
      <h1 className='text-2xl font-semibold'>Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  </>
  );
}

export default NotFoundPage;
