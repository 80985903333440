
import { Link } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import axios from 'axios'
import  { toast } from 'react-toastify';
import { useState } from 'react';
import useAuthStatus from '../hooks/useAuthStatus';

export default function PostCard({ wasca }) {
  
  const [bookmarked, setBookmarked] = useState(false)
  const { loggedIn } = useAuthStatus();

  const onMark = (e) => {
    const bookmarks = [];
    
    e.preventDefault();
    if(loggedIn) {
      setBookmarked(true) 
      axios.get('/bookmarks/fetchbookmarks')
        .then((response) => {
        for(let job of response.data) {
          bookmarks.push(job.wasca._id)
        }
        if(!bookmarks.includes(wasca._id)) {
          axios.post('/bookmarks/addbookmark', {wasca})
        }
      }).catch((error) =>{
        console.log(error)
      })
    } else {
      toast.error('please log in to save jobs')
    }
  }

  return (
    <>
    <div className='bg-white right-0 py-2 rounded-md shadow-slate-300 shadow-sm '>
    
    <div className='flex justify-between pr-5'>
    
      <Link to={`/wascas/wasca/${wasca._id}`} className='categoryListingLink'>
        <div className='p-3 flex flex-col gap-2'>
          <p className='text-lg font-bold'>{wasca.title}</p>
          <span className='italic text-xs font-semibold text-orange-500'>{wasca.tags[0]}</span>
          
          <p className='text-sm'>{wasca.meta.substring(0, 100)}...</p>
        </div> 
        </Link>
      <div className='flex items-center'>
        {loggedIn && (
        bookmarked === true ?
          <FaBookmark className='h-8 w-8 cursor-pointer hover:h-10' onClick={onMark} /> :
          <FaRegBookmark className='h-8 w-8 cursor-pointer hover:h-10' onClick={onMark} />
        
        )}
      </div>
    </div>
      
    
    </div>
    </>
  );
}