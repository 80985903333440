import Logo from '../assets/images/LOGO.png'



export default function Header() {

  return (
    <>
    <div className="hidden md:flex justify-between mr-5 mb-4 w-1/4">
        <div className="">
            <div className="">
              <img src={Logo} alt="" className='w-1/4 md:w-1/2' />
            </div>
        </div> 
    </div>
    </>
  );
}
