import React from 'react';
import Navbar from './Navbar';

const ServerError = () => {
  return (
  <>
    <Navbar />
    <div className='flex items-center flex-col justify-center h-screen'>
      <h1 className='text-lg font-semibold'>Something Went Wrong!</h1>
      <p>Sorry, we couldn't complete your request at this time. Please try again.</p>
    </div>
  </>
  );
}

export default ServerError;
