import React from 'react';
import Navbar from './Navbar';

const NotFound = () => {
  return (
  <>
    <Navbar />
    <div className='flex items-center flex-col justify-center h-screen'>
      <h1 className='text-lg font-semibold'>Something Went Wrong.</h1>
      <p><a href='/wascas'>Go back to search.</a></p>
    </div>
  </>
  );
}

export default NotFound;
