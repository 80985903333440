import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import arrowRight from '../assets/svg/keyboardArrowRightIcon.svg';
import { FaSearch } from "react-icons/fa";
import axios from 'axios';
import Navbar from '../components/Navbar';
import Spinner from '../components/Spinner';
import Login from './Login';


function Profile() {
  const [loading, setLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [active, setActive] = useState(false);
  const [formData, setFormData] = useState({
    sName: '',
    email: '',
  });

  const {email} = formData;
  const [foundUser, setFoundUser] = useState(false);
  
  useEffect(() => {
    const fetchUser = async () => {
      const response = await axios.get('/logged-in', { withCredentials: true });
      if (response.data.user) {
        setFormData(response.data.user)
        setActive(response.data.user.subscribed)
        setFoundUser(true)
      } else {
        setFoundUser(false)
      }
      setLoading(false);
    };
    fetchUser();
  }, []);


  const navigate = useNavigate();

  const onLogout = () => {
    axios.get('/logout')
    navigate('/wascas')
  }

  const handlePayment = async () => {
    try {
      const response = await axios.post('/subscriptions/create-payment')
      // window.location.href = response.data.redirect_url;
      setPaymentUrl(response.data.redirect_url)
    } catch (error) {
      console.error('Payment error:', error);
    } 
  }

  if(loading) {
    return <Spinner />
  }
  if(!foundUser) {
    return <Login />
  }

  return (
    <div className='profile mx-2'>
      <header className='profileHeader mb-8 mt-4'>
        <p className='pageHeader'>
          My Profile 
        </p>
        
        <button className='bg-orange-600 text-white rounded-full text-base px-6 py-1 font-semibold' type='button' onClick={onLogout}>
          Logout
        </button>
      </header>

      <main>
      <div className="profileDetailsHeader">
          <p className="profileDetailsText">Personal Details</p>
        </div>

        <div className="profileCard">
          <p>{email}</p> 
        </div> 

        <>
        <Link to='/wascas' className='createListing'>
          <FaSearch style={{width: 40}} className='' />
          <p>Search available jobs</p>
          <img src={arrowRight} alt="arrow right" />
        </Link>

        </>

       
        {active ? (
          <div className="profileDetailsHeader mt-16">
            <p className="profileDetailsText">Member Status</p>
            <p className="changePersonalDetails text-green-600">
              active
            </p>
          </div>
        ) : (
          <div className='flex flex-col justify-center text-sm profileDetailsHeader'>
            <hr className='mb-8' />
            <p>-Subscribe to automatically get access to client details for the jobs you're interested in.</p>
            <p>-Get access to all job posts.</p>
            <p>-Get personalized recommendations on how to approach each client.</p>
            <p>-Get email alerts for new clients and jobs.</p>
            <p onClick={handlePayment} className='w-3/4 md:w-1/2 mt-2 cursor-pointer flex self-center items-center justify-center bg-gradient-to-r from-orange-500 to-gray-900 text-white rounded-full px-4 p-2 font-semibold'>Subscribe<span className='text-xs ml-1'>$12/month</span></p>
          </div>
        )}
        
        {paymentUrl && (
          <iframe src={paymentUrl} width="600" height="385" title="Payment" className='mt-12'></iframe>
        )}
        <hr className='mt-8' />

      </main>
      <Navbar />
    </div>
  )

}

export default Profile