import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EditorMain from '../components/EditorMain'
import Header from '../components/Header'
import axios from 'axios'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import Navbar from '../components/Navbar'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function AddWasca() {

  const [formData, setFormData] = useState({
    title: '',
    meta: '',
    company: '',
    content: '',
    contact: '',
    notes: '',
    contactUrl: '',
    alternativeContact: '',
    longTerm: false,
    remote: false,
    agency: false,
    freelancers: false,
    freelancersOnly: false,
    annotation: false,
    translation: false,
    transcription: false,
    dataCollection: false,
  })

  const { title,meta,company,contactUrl,alternativeContact,longTerm,remote,agency,freelancers,freelancersOnly,annotation,transcription,translation,dataCollection } = formData;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const tags = Object.keys(formData)
      .filter(key => typeof formData[key] === 'boolean' && formData[key])
      .map(key => key);

    // Combine the form data with the mapped boolean fields
    const combinedData = {
      ...formData,
      tags
    };

    await axios.post('/wascas/postwasca', combinedData)
    .then((response) => {
      if(response.data.error) {
        toast.error(response.data.error);
      } else {
        toast.success('Saved successfully!');
        setLoading(false)
        navigate(`/wascas/wasca/${response.data._id}`)
      }
    })
    
  };

  if(loading) {
    return <Spinner />
  }

  return (
    <>
    <Header />
    <div className='md:w-1/2 px-2 mx-auto mt-20 pb-20 text-sm'>
      <form onSubmit={handleSubmit}>
        <div className='mb-5'>     
          <label htmlFor="title">Wasca Title</label> 
          <input 
              type="text" 
              id='title'
              value={title}
              className="mt-1 focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 rounded-md py-2 pl-2 ring-1 ring-slate-200 shadow-sm" 
              onChange={onChange}
          />
        </div>
        <div className='mb-5'>     
          <label htmlFor="title">Company</label> 
          <input 
              type="text" 
              id='company'
              value={company}
              className="mt-1 focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 rounded-md py-2 pl-2 ring-1 ring-slate-200 shadow-sm" 
              onChange={onChange}
          />
        </div>
        <div className='mb-5'>     
          <label htmlFor="title">Meta</label> 
          <textarea 
              type="text" 
              id='meta'
              value={meta}
              className="mt-1 focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 rounded-md py-2 pl-2 ring-1 ring-slate-200 shadow-sm" 
              onChange={onChange}
          />
        </div>
        <div className='mb-5'>     
          <label htmlFor="contactUrl">Contact Url</label> 
          <input 
              type="text" 
              id='contactUrl'
              value={contactUrl}
              className="mt-1 focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 rounded-md py-2 pl-2 ring-1 ring-slate-200 shadow-sm" 
              onChange={onChange}
          />
        </div>
        <div className='mb-5'>     
          <label htmlFor="alternativeContact">Alternative Contact</label> 
          <input 
              type="text" 
              id='alternativeContact'
              value={alternativeContact}
              className="mt-1 focus:ring-2 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 rounded-md py-2 pl-2 ring-1 ring-slate-200 shadow-sm" 
              onChange={onChange}
          />
        </div>
        <div className='mb-5'>
          <div className='mb-2'>
            <label><input type="checkbox" id="longTerm" name="longTerm" onChange={onChange} checked={longTerm} /> long-term</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='remote' name="remote" onChange={onChange} checked={remote} /> remote</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='agency' name="agency" onChange={onChange} checked={agency} /> agency</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='freelancers' name="freelancers" onChange={onChange} checked={freelancers} /> freelancers</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='freelancersOnly' name="freelancersOnly" onChange={onChange} checked={freelancersOnly} /> freelancers only</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='annotation' name="annotation" onChange={onChange} checked={annotation} /> annotation</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='translation' name="translation" onChange={onChange} checked={translation} /> translation</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='transcription' name="transcription" onChange={onChange} checked={transcription} /> transcription</label>
          </div>
          <div className='mb-2'>
            <label><input type="checkbox" id='dataCollection' name="dataCollection" onChange={onChange} checked={dataCollection} /> data collection</label>
          </div>

        </div>
        
 
        <EditorMain 
          formData={formData}
          setFormData={setFormData} />

        <div className=''>
        <ReactQuill 
          theme="snow" 
          required
          placeholder='Write how to apply/reach out...'
          className='h-72 mb-12'
          onChange={(value) => {
            setFormData({ ...formData, contact: value });
        }}
        />
        </div>

        <div className=''>
        <ReactQuill 
          theme="snow" 
          required
          placeholder='Write some notes...'
          className='h-72 mb-12'
          onChange={(value) => {
            setFormData({ ...formData, notes: value });
        }}
        />
        </div>

        <div >
          <button type='submit' className='flex bg-gradient-to-r from-fuchsia-700 to-sky-700 text-white w-full py-2 rounded-md items-center justify-center'>Save Changes</button>
        </div>
        
      </form>

   
    </div>
    <Navbar />
    
    </>
    
  )
}

export default AddWasca