import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditorMain({ formData, setFormData }) {
  
  return (
    <div className=''>
      <ReactQuill 
        theme="snow" 
        required
        placeholder='Write something...'
        className='h-72 mb-12'
        onChange={(value) => {
          setFormData({ ...formData, content: value });
        }}
        
      />;
    </div>
  )
}

export default EditorMain