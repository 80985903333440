
import { Link } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import diversity from '../assets/images/diversity.jpg'
import axios from 'axios'
import { useState } from 'react';

export default function BookPostCard({ wasca, bookmark, bookmarks, setBookmarks }) {

  const [bookmarked, setBookmarked] = useState(true)

  const onDelete = async () => {
    axios.post(`/bookmarks/${bookmark._id}`, {bookId: bookmark._id})
    setBookmarked(false)
    setBookmarks(bookmarks.filter((item) => item._id !== bookmark._id));
  }

  return (
    <>
    <div className='right-0 py-4 rounded-md shadow-slate-300 shadow-sm '>
    
    <div className='flex justify-between pr-5'>
    
      <Link to={`/wascas/wasca/${wasca._id}`} className='categoryListingLink'>
        <div className='p-3 flex flex-col gap-2'>
          <p className='text-lg font-semibold text-orange-600'>{wasca.title}</p>
          <span className='italic text-xs font-semibold text-orange-500'>{wasca.tags[0]}</span>
          <span className='text-xs italic'>2 days ago</span>
          <p className='text-sm'>{wasca.meta.substring(0, 100)}...</p>
        </div>
        </Link>
      <div className='flex items-center'>
        {bookmarked === false ?
          <FaRegBookmark className='h-8 w-8 cursor-pointer hover:h-10' onClick={onDelete} /> :
          <FaBookmark className='h-8 w-8 cursor-pointer hover:h-10' onClick={onDelete}  />
        }
      </div>
    </div>
      
    
    </div>
    </>
  );
}