import React from 'react'
import Logo from '../assets/images/LOGO.png'
import { FaArrowLeft } from 'react-icons/fa6';


function ResetFail() {

  return (
    <div className='flex flex-col h-screen'>
      <div className="flex justify-center pb-16 h-1/6 items-center pt-12">
        <img src={Logo} alt="" className='w-2/3 md:w-1/3 lg:w-1/4 xl:w-1/5' />
      </div>
      
      <div className='flex flex-col items-center h-1/2 justify-center bg-white px-4'>
        <div className='flex justify-center w-full mb-8'>
          
          <a href="/forgot" className='text-orange-500 text-base flex items-center'>
            <FaArrowLeft className='pr-1' />
            Resend Link?
          </a>
        </div>
        <div className='flex flex-col items-center justify-center'>
            <p className='text-2xl mb-8 font-semibold'>Failed to Reset!</p>
            <p className='mb-8'>Sorry, maybe you can try that again!</p>
            <p className='text-xs'>Remember, the reset link expires after 30 minutes</p>
        </div>
      </div>
      
      <div class="flex justify-center items-end h-1/4">
        <p>&copy; Freelients 2023. All rights reserved</p>
      </div>
      
    </div>
  )
}

export default ResetFail