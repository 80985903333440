import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/LOGO.png'
import { FaArrowLeft } from "react-icons/fa";
import axios from 'axios';
import Spinner from '../components/Spinner';

function Forgot() {

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    axios.post('/users/forgot', {email: email})
    setLoading(false)
    navigate('/forgot-password/success')

  }

  if(loading) {
    return <Spinner />
  }
  return (
    <div className='flex flex-col h-screen'>
      <div className="flex justify-center pb-16 h-1/6 items-center pt-12">
        <img src={Logo} alt="" className='w-2/3 md:w-1/3 lg:w-1/4 xl:w-1/5' />
      </div>
      
      <div className='flex flex-col items-center h-1/2 justify-center bg-white px-4'>
        <div className='flex justify-center w-full mb-8'>
          
          <Link to="/login" className='text-orange-500 text-sm flex items-center'>
            <FaArrowLeft className='pr-1' />
            Back to login
          </Link>
        </div>
        <div className='flex flex-col items-center justify-center'>
        <p className='text-2xl mb-8 font-semibold'>Forgot Your Password?</p>
        <p className='mb-8'>No worries! Enter the email address that you signed up with to reset the password.</p>
        <form onSubmit={onSubmit} className='flex flex-col w-full'>
        <input 
          type="text"
          value={email}
          id='email'
          onChange={onChange}
          placeholder='Email'
          className='rounded-lg outline-none'
        />
        <button className='bg-gradient-to-r from-orange-500 via-slate-800 to-gray-950 hover:bg-gradient-to-l w-full text-white text-bold rounded-full py-3 mt-6'>Send reset instructions</button>
        </form>
        </div>
      </div>
      
      <div className="flex justify-center items-end h-1/4">
        <p>&copy; Freelients 2023. All rights reserved</p>
      </div>
      
    </div>
  )
}

export default Forgot