import React from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as ExploreIcon } from '../assets/svg/exploreIcon.svg';
import { ReactComponent as PersonOutlineIcon } from '../assets/svg/personOutlineIcon.svg';
import { FaRegBookmark } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const pathMatchRoute = (route) => {
      if(route === location.pathname) {
          return true
      }
  }

  return (
    <footer className="navbar">
        <nav className="navbarNav md:w-3/5 lg:w-1/2">
            <ul className="navbarListItems">
                
                <li className="navbarListItem" onClick={() => navigate('/')}>
                    <ExploreIcon fill={pathMatchRoute('/') ? '#2c2c2c' : '#8f8f8f'} width='20px' height='20px' />
                    <p className={pathMatchRoute('/') ? 'navbarListItemNameActive' : 'navbarListItemName'}>Home</p>
                </li>
                <li className="navbarListItem" onClick={() => navigate('/wascas')}>
                    <FaSearch fill={pathMatchRoute('/wascas') ? '#2c2c2c' : '#8f8f8f'} width='20px' height='20px' />
                    <p className={pathMatchRoute('/wascas') ? 'navbarListItemNameActive' : 'navbarListItemName'}>Search</p>
                </li>
                <li className="navbarListItem" onClick={() => navigate('/wascas/bookmarks')}>
                    <FaRegBookmark fill={pathMatchRoute('/wascas/bookmarks') ? '#2c2c2c' : '#8f8f8f'} width='20px' height='20px' />
                    <p className={pathMatchRoute('/wascas/bookmarks') ? 'navbarListItemNameActive' : 'navbarListItemName'}>Saved</p>
                </li>
                
                <li className="navbarListItem" onClick={() => navigate('/user/profile')}>
                    <PersonOutlineIcon fill={pathMatchRoute('/user/profile') ? '#2c2c2c' : '#8f8f8f'} width='20px' height='20px' />
                    <p className={pathMatchRoute('/user/profile') ? 'navbarListItemNameActive' : 'navbarListItemName'}>Profile</p>
                </li>  
            </ul>
        </nav>
    </footer>
  )
}

export default Navbar